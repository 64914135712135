.download{
    position: relative;
    display: flex;
    gap: 2rem; 
}

.images{
    height: 8rem;
    width: 10rem;
}

.images>:nth-child(1){
    border: 2px solid #232A4E;
    border-radius: 13px;
    height: 3rem;
    width: 10rem;
}