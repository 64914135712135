.search{
    background-color: #081730;
    z-index: 1;
    height: 65rem;
    padding-top: 18rem;
    padding-bottom: 10rem;
    position: relative;
    border-radius: 5rem;
    margin-top: -15rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0 5rem;
}

.left{
    flex: 1;
    position: relative;
}

.left>:nth-child(1){
    z-index: -1;
    position: absolute;
    width: 46rem;
    top: 3rem;
    left: -10rem;
}

.left>:nth-child(2){
    position: absolute;
    top: -13rem;
    left: 5rem;
    width: 22rem;
    height: 44rem;
    z-index: -1;
}

.left>:nth-child(3){
    position: absolute;
    top: -4rem;
    left: 7rem;
    width: 12rem;
}

.left>:nth-child(4){
    position: absolute;
    top: -3.5rem;
    left: 19rem;
    width: 9rem;
}

.left>:nth-child(5){
    position: absolute;
    top: 20.5rem;
    left: 3rem;
    width: 19rem;
}

.right{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    padding-top: 9rem;
}

.searchbar{
    display: flex;
    justify-content: start;
    width: 100%;
    padding-top: 10rem;
}

.searchIcon{
    width: 1.5rem;
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 1rem;
    background: linearGradient(rgb(#F3071D, #E600FF));
    padding: 2rem;
    height: 3rem;
}

.searchbar>:nth-child(1){
    flex: 19;
    outline: none;
    background-color: #010917;
    border-radius: 15px;
    padding: 2rem;
    height: 3rem;
    
}

.tild{
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    align-items: center;
    width: 100%;
}

.tild>img{
    width: 5rem;
}

.paragraph{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.paragraph>:nth-child(1),.paragraph>:nth-child(2){
    font-size: 2.5rem;
}

.paragraph>:nth-child(3){
    color: #4d586A;
    margin-top: 1rem;  
}