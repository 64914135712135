.App{
    background-color: #081730;
    color: white;
    width: 100%;
    height:76.3vh;
    border-bottom: 2rem;
    
}

.app{
    padding: 2.4rem 0;
}