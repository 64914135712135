.musicPlayer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid black;
    padding: 1rem 2rem;
    background-color: #232A4E;
    margin-top: 2rem;
    border-radius: 1rem;
}

.upperPart{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile{
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
}

.details{
    position: absolute;
    display: flex;
    gap: 0.3rem;
    left: 5.2rem;
    justify-content: center;
    flex-direction: column;
}

.details>:nth-child(1){
    font-size: 1rem;
}

.details>:nth-child(2){
    font-size: 0.7rem;
    color: darkgrey;
}

.menuIcon{
    position: absolute;
    left: 50rem;
    transform: scale(0.3);
}

.lowerPart{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.track{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: small;
    color: gray;
    flex: 2;
    font-weight: 500;
}

.track>:nth-child(2){
    width: 16rem;
}

.controllers{

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
}

.pauseButton{
    position: absolute;
    align-items: center;
    justify-content: center;
}

.pause{
    position: absolute;
    left: 0.88rem;
    top: 0.79rem;
}