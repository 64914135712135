.footer{
    background-color: #081730;
    z-index: -99;
    height: 80vh;
    position: relative;
    bottom: -4rem;
    margin-top: -10rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.icons{
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    align-items: center;
    text-align: center;
    gap: 4rem;
    
}

.facebook, .twitter, .linkedin, .youtube{
    position: relative;
    transform: scale(1.5);
    width: 2rem;
    border: 2px solid white;
    border-radius: 100%;
}

.footer>:nth-child(1){
    align-items: center;
    justify-content: center;
}

.details{
    margin-top: 3rem;
}

.details>:nth-child(1){
    color: gray;
    font-weight: 400;
    padding: 0 15rem;
    text-align: center;
    margin-top: 4rem;
}