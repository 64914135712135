.download{
    background-color: #010917;
    width: 100%;
    z-index: -1;
    height: 100vh;
    padding-top: 15rem;
    position: relative;
    border-radius: 5rem;
    margin-top: -10rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.items{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.items>:nth-child(1){
    width: 5rem;
}

.items>:nth-child(2){
    font-weight: bold;
    font-size: 2rem;
}

.items>:nth-child(3){
    font-weight: bold;
    font-size: 2rem;
}

.items>:nth-child(4){
    color: gray;
    margin-bottom: 5rem;
}