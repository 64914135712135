.feature{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    
}

.icon{
    width: 3rem;
    height: 3rem;
    display: flex;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
    
}

.title{
    margin-top: 2rem;
    text-align: center;
}

.text{
    color: #707070;
    margin-left: 2rem;
    justify-content: center;
    text-align: center;
    margin-right: 2rem;
    width: 50%;
}

.more>:hover{
    cursor: pointer;
    color: #E600FF;
    margin-top: 3rem;
}

.bg{
    background-color: #081730;
    padding: 2rem;
    border-radius: 2rem;
}