.header{
    display: flex;
    position: relative;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    overflow: hidden;
    padding: 1rem 5rem;
}

.logo{
    height: 42px;
    width: 42px;
}

.buttons{
    display: flex;
    color: white;
    gap: 1rem;
    
}

.btn1, .btn2{
    border: 2px solid #232A4E;
    border-radius: 10px;
    padding: 7px 25px;
}

.buttons>:hover{
    background: #232A4E;
    cursor: pointer;
}


