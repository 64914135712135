.menu{
    display: flex;
}

.links{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 3rem;
}

.links>:hover{
    cursor: pointer;
}

