.wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 35rem;
    position: relative;
    padding: 0rem 5rem;
    border-bottom: 5rem;
    background-color: #081730;
    z-index: 3;
}

.headings{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.headings>:nth-child(1), .headings>:nth-child(2){
    font-weight: bold;
    font-size: 2rem;
}

.headings>:nth-child(3){
    color: #525D6E;
}

.images{
    position: relative;
    width: 50%;
}

.images>:nth-child(1){
    position: absolute;
    top: -8rem;
    left: 19rem;
}
.images>:nth-child(2){
    position: absolute;
    top: -15rem;
    left: 13rem;
    height: 34rem;
}

.images>:nth-child(3){
    position: absolute;
    top: 5.9rem;
    left: 233px;
    width: 175px;
}

.images>:nth-child(4){
    position: absolute;
    top: 12rem;
    left: 13rem;
    width: 5rem;
}

.images>:nth-child(5){
    position: absolute;
    top: 12rem;
    left: 12.5rem;
    width: 5rem;
}