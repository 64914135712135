.experience{
    background-color: #010917;
    width: 100%;
    z-index: 2;
    height: 80rem;
    padding-top: 18rem;
    position: relative;
    border-radius: 5rem;
    margin-top: -10rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.experience>:nth-child(1){
    width: 5rem;
    align-items: center;
    justify-content: center;
}

.heading{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}

.feature{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 6rem;
    width: 100%;
}